text {
    max-height: "6em";
    overflow: "hidden";
    text-overflow: "elypsis";
}

text::after {
    position: "absolute";
    width: "100%";
    background-image: "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 90%)";
}